/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import NavbarComponent from './components/navbar.component'

document.addEventListener('DOMContentLoaded', () => {
    //Component
    new NavbarComponent()

    //Front page only
    if ($('.home').length > 0) {
        import(
            '@scripts/services/home.service' /* webpackChunkName: "scripts/home.service" */
        ).then(({ default: HomeService }) => {
            new HomeService()
        })
    }

    //Fullscreen template only
    if ($('.fullscreen').length > 0) {
        import(
            '@scripts/services/layout.service' /* webpackChunkName: "scripts/layout.service" */
        ).then(({ default: LayoutService }) => {
            new LayoutService()
        })
    }

    // Scroll reveal integration
    if ($('.reveal').length > 0) {
        import(
            '@scripts/services/reveal.service' /* webpackChunkName: "scripts/reveal.service" */
        ).then(({ default: RevealService }) => {
            new RevealService()
        })
    }

    // Header js for slider
    if ($('#header-banner-slider').length > 0) {
        import(
            '@scripts/services/header.service' /* webpackChunkName: "scripts/header.service" */
        ).then(({ default: HeaderService }) => {
            new HeaderService()
        })
    }
})
